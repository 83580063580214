<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="flex flex-wrap items-center justify-between">
                <span class="font-semibold text-base">SIM CARD</span>
                <div class="flex">
                    <div class="text-xs relative">
                        <div class="absolute top-2.5 left-2.5">
                            <unicon name="search" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>
                        </div>
                        <input v-model="keyword" type="text"
                            class="block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs"
                            placeholder="Pencarian" />
                    </div>
                </div>
            </div>

            <!-- Loading Spinner -->
            <div v-if="isLoading" class="flex flex-col justify-center items-center h-40">
                <svg class="animate-spin h-8 w-8 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
                <p class="mt-2 text-gray-600 text-sm">Loading...</p>
            </div>


            <!-- Tabel Data -->
            <div v-else class="mt-4 w-full overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                            <td class="py-4">ICCID</td>
                            <td>MSISDN</td>
                            <td>Status</td>
                            <td>Cycle Usage</td>
                            <td>Limit</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filterData" :key="item.id"
                            class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                            <td class="py-4">
                                <router-link v-if="item.sim_id" :to="'/simcard/detail/' + item.sim_id"
                                    class="text-blue-600 hover:underline">
                                    {{ item.sim_id }}
                                </router-link>
                            </td>
                            <td>-</td>
                            <td>
                                <div v-if="item.status === 10" class="flex items-center space-x-2">
                                    <span class="h-2 w-2 rounded-full bg-blue-500"></span>
                                    <span class="text-sm font-medium text-gray-700">Pre-activated</span>
                                </div>
                                <span v-if="item.status === 23"
                                    class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded border border-green-400">
                                    Active
                                </span>
                                <div v-if="item.status === 30" class="flex items-center space-x-2">
                                    <span class="h-2 w-2 rounded-full bg-yellow-500"></span>
                                    <span class="text-sm font-medium text-gray-700">Pending</span>
                                </div>
                            </td>
                            <td>
                                <div v-if="item.sim_service.bundles.length > 0">
                                    <p v-for="(bundle, bIndex) in item.sim_service.bundles" :key="bIndex">
                                        {{ bundle.current_cycle_usage === 0 ? 'No Data' : (bundle.current_cycle_usage /
                                        1024 / 1024).toFixed(2) + 'MB' }}
                                    </p>
                                </div>
                                <div v-else>0.00MB</div>
                            </td>
                            <td>
                                <div v-if="item.sim_service.bundles.length > 0">
                                    <p v-for="(bundle, bIndex) in item.sim_service.bundles" :key="bIndex">
                                        {{ bundle.data_limit === 0 ? 'No Data' : (bundle.data_limit / 1024 /
                                        1024).toFixed(2) + 'MB/ Year' }}
                                    </p>
                                </div>
                                <div v-else>0.00MB</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Pagination -->
            <div class="flex justify-end items-center mt-4">
                <div class="font-medium">
                    <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                </div>
                <div class="flex">
                    <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"
                        @click="previousPage()"></unicon>
                    <unicon name="angle-right" class="cursor-pointer"
                        :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import globalMixin from '../../mixin/global';

export default {
    data() {
        return {
            data: [],
            totalRows: 100,
            perPage: 10,
            limit: 10,
            startNumber: 1,
            endNumber: 8,
            currentPage: 1,
            isLoading: false, // Tambahkan state untuk loading
            keyword: '',
        };
    },
    mixins: [globalMixin],
    methods: {
        async getData() {
            this.isLoading = true; // Tampilkan loading spinner

            try {
                let headers = {
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                    }
                };

                const res = await this.axios.get(`v1/cube/iot?page_no=${this.currentPage}&page_size=${this.limit}&keyword=${this.keyword}`, headers);

                this.data = res.data.data;
                this.totalRows = res.data.total_count;

                if (this.totalRows <= this.limit) {
                    this.endNumber = this.totalRows;
                } else if (this.currentPage > 1) {
                    let page = this.currentPage - 1;
                    this.startNumber = page * this.limit + 1;
                    this.endNumber = this.currentPage * this.limit;
                    if (this.totalRows < this.endNumber) {
                        this.endNumber = this.totalRows;
                    }
                } else {
                    this.startNumber = 1;
                    this.endNumber = this.limit;
                }

                this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1) * this.limit + 1;
            } catch (err) {
                this.errorResponse(err);
            } finally {
                this.isLoading = false; // Sembunyikan loading spinner setelah selesai
            }
        },
        ...mapActions({
            setUser: 'auth/setUser'
        }),
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            token: 'auth/token',
        }),
        filterData() {
            const query = this.keyword.toLowerCase();
            return this.data.filter(item =>
                item.sim_id.toLowerCase().includes(query) ||
                item.status.toString().includes(query) ||
                item.sim_service.bundles.some(bundle => bundle.id.toString().includes(query))
            );
        },
    },
    created() {
        this.getData();
    },
};
</script>